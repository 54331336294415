import AppDialog from "@components/AppDialog";
import { CallProvider } from "@context/CallContext";
import { ChatProvider } from "@context/ChatContext";
import { Outlet } from "react-router-dom";
import { NotificationProvider } from "src/context/NotificationContext";
import DrawersContainer from "./DrawersContainer";
import CallAlert from "./alerts/call-alert/CallAlert";
import BottomTabs from "./bottom-tabs/BottomTabs";
import ElasticDialog from "./elastic-dialog/ElasticDialog";
import AppIntervalChecks from "./interval-checks/AppIntervalChecks";
import MiniSidebar from "./mini-sidebar/MiniSidebar";

export default function MasterLayout() {
  /** */

  return (
    <NotificationProvider>
      <CallProvider>
        <ChatProvider>
          <div className="h-screen flex flex-col md:flex-row overflow-hidden">
            <MiniSidebar />
            <div className="h-screen pb-32 md:pb-0 flex-1 bg-[#F8F9FA] overflow-y-auto">
              <Outlet />
            </div>
            {/* Mobile Bottom Tabbar */}
            <BottomTabs />
          </div>

          {/* Global Alert Dialog */}
          <AppDialog />

          {/* Global Elastic Search Dialog */}
          <ElasticDialog />

          {/* Global Drawers Cotainer */}
          <DrawersContainer />

          {/* Interval Checks */}
          <AppIntervalChecks />

          {/* Call Alert */}
          <CallAlert />
        </ChatProvider>
      </CallProvider>
    </NotificationProvider>
  );
}
