import Alert from "@components/Alert";
import AppButton from "@components/AppButton";
import AppDivider from "@components/AppDivider";
import AppIcon from "@components/AppIcon";
import Locale from "@components/core/Locale";
import ENDPOINTS from "@config/endpoints";
import axios from "axios";
import { Field, Formik } from "formik";
import queryString from "query-string";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "src/context/AuthContext";
import toast from "src/helpers/toast";
import { AppInput, RowInput } from "../../components/form";

// Format Countdown (Seconds -> MM:SS)
const formatCountdown = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export default function LoginPage() {
  /** */

  const navigate = useNavigate();

  const { login } = useContext(AuthContext);

  const URLParams = queryString.parse(useLocation().search);
  const { token, alreadySent, resendEnablesIn } = URLParams ?? {};

  // States
  const [codeGenerated, setCodeGenerated] = useState(alreadySent === "true");
  const [masked, setMasked] = useState(URLParams?.masked);
  const [mode, setMode] = useState(URLParams?.mode);
  const [countdown, setCountdown] = useState(parseInt(resendEnablesIn) || 0);

  // Get Public Assets
  const GPM = useMutation(() => axios.get(`${ENDPOINTS.app.public}/${window.location.href.split("/")[2]}`));

  // Generate Token Mutation
  const GTM = useMutation((mode) => axios.post(`${ENDPOINTS.app.auth}/2fa/generate`, { token, mode }), {
    onSuccess: (response) => {
      toast.success("TFA Code Sent");
      setCodeGenerated(true);
      setMasked(response?.masked); //
      setMode(response?.mode);
      setCountdown(parseInt(resendEnablesIn) || 60); // Reset countdown when new code is generated
    },
  });

  // Submit Code Mutation
  const SCM = useMutation((values) => axios.post(`${ENDPOINTS.app.auth}/2fa/verify`, { ...values, token, mode }), {
    onSuccess: (response) => {
      const token = response?.token;
      if (token) login(token);
    },
    onError: (error) => console.log(error),
  });

  useEffect(() => {
    GPM.mutate(); // Public Assets
  }, []);

  // Countdown Effect
  useEffect(() => {
    let timer;
    if (codeGenerated && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [codeGenerated, countdown]);

  return (
    <div className="flex h-screen">
      {/* Left side - Image */}
      <div className="hidden lg:block lg:w-1/2 relative">
        {/* <img src={`${ENDPOINTS.cdn}/public/medias/${GPM.data?.banner || "default-banner.jpg"}`} className="h-full w-full object-cover" alt="Login banner" /> */}
        <div className="absolute inset-0 bg-black/40" /> {/* Dark overlay */}
      </div>

      {/* Right side - Form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center px-6 lg:px-12 relative">
        <button
          onClick={() => navigate("/auth/login")}
          className="absolute top-6 left-6 flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors"
        >
          <AppIcon code="fa-arrow-left" className="w-5 h-5" />
          <span className="text-sm font-medium">Back to login</span>
        </button>

        <div className="w-full max-w-md">
          <div className="flex flex-col items-center mb-8">
            <img className="h-12 w-auto mb-6 object-contain" src={`${ENDPOINTS.cdn}/public/medias/${GPM.data?.logo}`} alt="Company logo" />
            <div className="flex items-center gap-2 mb-2">
              <AppIcon code="fa-shield-check" className="w-6 h-6 text-primary-600" />
              <h1 className="text-2xl font-semibold text-gray-900">
                <Locale code="T.TFA" />
              </h1>
            </div>
            <p className="text-gray-500 text-center">
              <Locale code="T.TFA_DESCRIPTION" />
            </p>
          </div>

          <div className="flex flex-col space-y-4">
            <Formik initialValues={{ code: "" }} onSubmit={SCM.mutate}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate className="space-y-4">
                  {codeGenerated && mode !== "authenticator" && (
                    <Alert
                      body={
                        <div className="flex flex-col">
                          <span>
                            <Locale code={`T.TFA_${mode?.toUpperCase()}_SENT`} />
                          </span>
                          <span className="truncate">({masked})</span>
                        </div>
                      }
                      mode="success"
                    />
                  )}
                  <div className="flex items-center space-x-2">
                    <Field
                      required
                      type="text"
                      name="code"
                      component={RowInput}
                      FormElement={AppInput}
                      placeholder={mode === "authenticator" ? "T.ENTER_CODE_AUTHENTICATOR" : "T.ENTER_CODE_RECEIVED"}
                      className="text-center text-2xl tracking-wide flex-grow"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleSubmit();
                        }
                      }}
                    />
                    <AppButton mode="primary" title="BTN.SUBMIT" type="submit" className="w-32" loading={SCM.isLoading} />
                  </div>
                </form>
              )}
            </Formik>
            <AppDivider title="T.RECEIVE_CODE_VIA" />
            <div className="flex flex-col space-y-2">
              {/* Alternative TFA Methods */}
              {countdown ? (
                <div className="flex flex-col items-center justify-center h-12">
                  <span className="text-sm text-gray-500">
                    <Locale code="T.TFA_RESEND_AVAILABLE_IN" /> {formatCountdown(countdown)}s
                  </span>
                </div>
              ) : (
                <div className="grid grid-cols-3 gap-2">
                  {mode !== "authenticator" && (
                    <>
                      <AppButton
                        mode="light"
                        title="BTN.WHATSAPP"
                        loading={GTM.isLoading && mode === "whatsapp"}
                        onClick={() => GTM.mutate("whatsapp")}
                        disabled={GTM.isLoading || countdown > 0}
                      />
                      <AppButton
                        mode="light"
                        title="BTN.SMS"
                        icon="fa-mobile"
                        loading={GTM.isLoading && mode === "sms"}
                        onClick={() => GTM.mutate("sms")}
                        disabled={GTM.isLoading || countdown > 0}
                      />
                      <AppButton
                        mode="light"
                        title="BTN.EMAIL"
                        icon="fa-envelope"
                        loading={GTM.isLoading && mode === "email"}
                        onClick={() => GTM.mutate("email")}
                        disabled={GTM.isLoading || countdown > 0}
                      />
                    </>
                  )}
                  {mode !== "authenticator" && (
                    <AppButton mode="light" className="col-span-full" title={"T.GOOGLE_AUTHENTICATOR"} onClick={() => setMode("authenticator")} />
                  )}
                  {mode === "authenticator" && (
                    <AppButton mode="light" title="T.WHATSAPP_SMS_EMAIL" onClick={() => setMode(URLParams?.mode)} className="col-span-full" />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
