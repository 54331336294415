import { useIntl } from "react-intl";
import Select from "react-select";

export default function AppSelect({
  name,
  value,
  onChange,
  multiple = false,
  options = [],
  loading = false,
  required = false,
  locale = false,
  grouped = false,
  ...props
}) {
  const intl = useIntl();

  const customStyles = {
    control: (props) => ({
      ...props,
      border: "1px solid #e4e6ef",
      borderRadius: 0,
    }),
    groupHeading: (base) => ({
      ...base,
      backgroundColor: "#f9fafb",
      padding: "8px 12px",
    }),
  };

  const processOptions = () => {
    if (grouped) {
      return options.map((group) => ({
        label: locale ? intl.formatMessage({ id: group.label ?? "-" }) : group.label,
        options: group.options.map((opt) => ({
          label: locale ? intl.formatMessage({ id: opt.label ?? "-" }) : opt.label,
          value: opt.value,
        })),
      }));
    }

    return (props?.placeholder ? [{ label: props.placeholder, value: null }, ...options] : options)?.map(({ label, value }) =>
      locale
        ? {
            label: intl.formatMessage({ id: label ?? "-" }),
            value,
          }
        : { label, value }
    );
  };

  const _options = processOptions();

  const findSelectedValue = () => {
    if (!grouped) {
      return multiple ? _options.filter((option) => value?.includes(option.value)) : _options?.find((option) => option.value === value) ?? null;
    }

    const allOptions = _options.flatMap((group) => group.options);
    return multiple ? allOptions.filter((option) => value?.includes(option.value)) : allOptions.find((option) => option.value === value) ?? null;
  };

  return (
    <Select
      className="flex-1"
      styles={customStyles}
      isDisabled={props.disabled}
      instanceId={name}
      options={_options}
      isOptionDisabled={required ? ({ value }) => !Boolean(value) : undefined}
      onChange={(event) => (multiple ? onChange(event?.map(({ value }) => value)) : onChange(event?.value))}
      value={findSelectedValue()}
      isLoading={loading}
      isMulti={multiple}
      {...props}
    />
  );
}
