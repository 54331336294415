import { AuthContext } from "@context/AuthContext";
import AuthPage from "@modules/auth/AuthPage";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoggedInAuthPage from "src/modules/auth/LoggedInAuthPage";
import LogoutScreen from "../components/screens/LogoutScreen";
import PrivateRoutes from "./PrivateRoutes";

export default function AppRoutes() {
  const { isLogedIn } = useContext(AuthContext);
  return (
    <Routes>
      <Route path="logout" element={<LogoutScreen />} />
      {isLogedIn ? (
        <>
          <Route path="/*" element={<PrivateRoutes />} />
          <Route path="/auth" element={<LoggedInAuthPage />} />
          <Route index element={<Navigate to="/dashboard" />} />
        </>
      ) : (
        <>
          <Route path="auth/*" element={<AuthPage />} />
          <Route path="*" element={<Navigate to="/auth" />} />
        </>
      )}
    </Routes>
  );
}
