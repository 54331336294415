import clx from "classnames";
import { useIntl } from "react-intl";
export default function AppDivider({ className, title }) {
  const intl = useIntl();
  return (
    <div className={clx("flex items-center ml-2", className)}>
      {title && <span className="block w-fit truncate  text-gray-400">{intl.formatMessage({ id: title ?? "-" })}</span>}
      <div className={`border-t flex-1 ${title && "ml-2"}`} />
    </div>
  );
}
