import AppIcon from "./AppIcon";

const _BODY_CLASSES = {
  basic: "border-gray-400 bg-gray-50",
  warning: "border-yellow-400 bg-yellow-50",
  danger: "border-red-400 bg-red-50",
  success: "border-green-400 bg-green-50",
  primary: "border-blue-400 bg-blue-50",
};

const _TEXT_CLASSES = {
  basic: "",
  warning: "text-yellow-700",
  danger: "text-red-700",
  success: "text-green-700",
  primary: "text-blue-700",
};

const _ICON_CLASSES = {
  basic: "text-gray-400",
  warning: "text-yellow-400",
  danger: "text-red-400",
  success: "text-green-400",
  primary: "text-blue-400",
};

export default function Alert({ mode, icon, body, className }) {
  return (
    <div className={`flex items-center border-l-4 pl-4 py-2 pr-2 space-x-4 ${_BODY_CLASSES[mode]} ${className}`}>
      {icon && <AppIcon code={icon} className={`h-8 w-8 ${_ICON_CLASSES[mode]}`} aria-hidden="true" />}
      <div className={`${_TEXT_CLASSES[mode]} w-full`}>{body}</div>
    </div>
  );
}
