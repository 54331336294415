import { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "src/context/AppContext";
import { AppLayoutContext } from "src/context/AppLayoutContext";
import { NotificationContext } from "src/context/NotificationContext";
import MiniSidebarItem from "./components/MiniSidebarItem";

export default function MiniSidebar() {
  const { notification, call } = useContext(AppLayoutContext);
  const {
    CM: { isServiceEnabled },
  } = useContext(AppContext);

  // Callbacks
  const _handleOpenCallCenter = useCallback(() => call.show(), []);
  const _handleOpenNotifications = useCallback(() => notification.show(), []);

  const { notifications } = useContext(NotificationContext);

  // Sidebar Items
  const _SIDEBAR = [
    {
      title: "T.DASHBOARD",
      path: "/dashboard",
      icon: "fa-dashboard",
      // media: "snowman",
    },
    { title: "T.LEADS", path: "/leads", icon: "fa-users" },
    { title: "T.SEGMENTS", path: "/workflow", icon: "fa-compass" },
    { title: "T.CALENDAR", path: "/calendar", icon: "fa-calendar" },
    {
      title: "T.MESSENGER",
      path: "/messenger",
      icon: "fa-sms",
      notification: notifications?.some(({ seen, code }) => code === "NEW.MESSAGE" && !seen),
    },
    { title: "T.CALLCENTER", service: "call", icon: "fa-phone", onClick: _handleOpenCallCenter },
    { title: "T.NOTIFICATIONS", icon: "fa-bell", onClick: _handleOpenNotifications },
    { title: "T.SETTINGS", icon: "fa-cog", path: "/settings/general" },
    { title: "T.REPORTS", icon: "fa-chart", path: "/reports" },
    { title: "T.BOOKING", service: "booking", icon: "fa-truck", path: "/booking" },
  ].filter(({ service }) => (!service ? true : isServiceEnabled(service)));

  return (
    <div className=" hidden md:flex flex-shrink-0 border-r w-20 flex-col bg-white">
      {_SIDEBAR.map(({ icon, media, active, path, notification, onClick }, _index) => (
        <>
          {path ? (
            <Link to={path}>
              <MiniSidebarItem key={_index} icon={icon} media={media} active={active} notification={notification} />
            </Link>
          ) : (
            <MiniSidebarItem key={_index} icon={icon} media={media} active={active} notification={notification} onClick={onClick} />
          )}
        </>
      ))}
    </div>
  );
}
