import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { AppConfigProvider } from "./context/AppContext";
import { AppLayoutProvider } from "./context/AppLayoutContext";
import { AuthProvider } from "./context/AuthContext";
import I18nProvider from "./i18n/I18nProvider";
import AppRoutes from "./routes/AppRoutes";

// Global Styles
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { GOOGLE_OAUTH_CLIENT_ID } from "./config";
import "./index.css";
import facebookService from "./services/facebook.service";

// Facebook SDK
facebookService.init();

// Google SDK

export default function App() {
  const queryClient = new QueryClient();
  return (
    <BrowserRouter basename="/">
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
            <AppConfigProvider>
              <I18nProvider>
                <AppLayoutProvider>
                  <AppRoutes />
                </AppLayoutProvider>
                <ToastContainer />
              </I18nProvider>
            </AppConfigProvider>
          </GoogleOAuthProvider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}
